import React, { useState } from 'react';
import './SubtitleSummary.css';
// import { applyColors } from './applyColors';
// applyColors()

function SubtitleSummary({ title, subtitle, summary }) {
  const [activeTab, setActiveTab] = useState('summary');

  return (
    <div className="subtitle-summary">
      <h2>{title}</h2>
      <div className="tab-buttons">
        <button 
          className={activeTab === 'summary' ? 'active' : ''} 
          onClick={() => setActiveTab('summary')}
        >
          요약
        </button>
        <button 
          className={activeTab === 'subtitle' ? 'active' : ''} 
          onClick={() => setActiveTab('subtitle')}
        >
          자막
        </button>
      </div>
      <div className="content">
        {activeTab === 'subtitle' && <p>{subtitle}</p>}
        {activeTab === 'summary' && <p>{summary}</p>}
      </div>
    </div>
  );
}

export default SubtitleSummary;